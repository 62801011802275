import tiketti from './baseApi';

const defaults = {
  url: process.env.REACT_APP_API_URL_EVENTS
};

export const getAllEvents = async (
  {
    locale,
    etype = null,
    page = 0,
    pageSize = -1,
    organizerID = null  
  }
) => {
  try {
    const url = `${defaults.url}/events`;
    const params = { locale, page, pageSize, etype, organizerID, admin: true };

    return await tiketti.get(url, params);
  } catch (error) {
    return [];
  }
};
